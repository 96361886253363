import styled from 'styled-components';


export const FlexWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const AboutWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background-color: ${props => props.theme.color.primary};
  overflow-x: hidden;

`

export const TitleWrapper = styled.div`
  position: relative;
  padding: 50px;
  padding-top: 100px;
  color: ${props => props.theme.color.bright};
  font-size: ${props => props.theme.fontSize.lg};
  font-family: ${props => props.theme.font.primary};
  letter-spacing: .15rem;
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSize.ml};
  }
`

export const TextWrapper = styled.div`
  padding: 50px;
  color: ${props => props.theme.color.bright};
  font-size: ${props => props.theme.fontSize.sm};
  text-align: justify;
  line-height: 26px;
  font-weight: 300;
`

export const PolygonWrapper = styled.div`
  height: 100vh;
  width: 100%;
  clip-path: polygon(40% 0, 50% 0, 73% 100%, 63% 100%);
  shape-outside: polygon(40% 0, 50% 0, 73% 100%, 63% 100%);
  float: right;
  shape-margin: 3%;
  @media screen and (max-width: 700px) {
    display: none;
  }
`

export const OwnerWrapper = styled.div`
  position: absolute;
  right: 10vw;
  top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: right;
  height: auto;
  @media screen and (max-width: 700px) {
    position: relative;
    right: auto ;
    top: auto;
    float: none;
  }
`

export const OwnerName = styled.div`
  padding: 10px;
  padding-top: 30px;
  color: ${props => props.theme.color.secondary};
  font-size: ${props => props.theme.fontSize.ml};
  font-family: ${props => props.theme.font.primary};
  letter-spacing: .15rem;
`

export const OwnerImg = styled.img`
  width: 20vw;
  min-width: 250px;
`

export const OwnerProfession = styled.div`
  padding: 10px;
  color: ${props => props.theme.color.secondary};
  font-size: ${props => props.theme.fontSize.xs};
  font-style: italic;
  margin-bottom: 100px;
`



