import React from 'react'
import logo from "../assets/images/logo.png"
import HomePage from '../components/Home'
import AboutPage from '../components/About'
import ServicesPage from '../components/Services'
import ContactPage from '../components/Contact'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'

const Home = () => {
  return (
    <div className='site-wrapper'>
      <Helmet>
        <title>Services de comptabilité Laval | Comptabilité ND</title>
        <meta name="description" content="Comptabilité à Laval pour la tenue de livres, production des impôts, états financiers, service de paie, etc." />
        <link rel='canonical' href='/' />
        <meta name="keywords" content={"Comptabilité Laval, Comptable, Impôts, Service de paie, Tenue de livres"}/>
        <meta property="og:title" content={"Services de comptabilité Laval | Comptabilité ND"}/>
        <meta property="og:description" content={"Comptabilité à Laval pour la tenue de livres, production des impôts, états financiers, service de paie, etc."}/>
      </Helmet>
      <h1 className='seo-hidden'>Services de comptabilité Laval | Comptabilité ND</h1>
      <HomePage />
      <AboutPage />
      <ServicesPage />
      <ContactPage />
      <Footer />
    </div>
  )
}

export default Home