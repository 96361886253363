import React from 'react'
import logo from "../../assets/images/logo.png"
import { HomeWrapper, LinkWrapper, LinksWrapper, LogoWrapper } from './HomeElements'

const HomePage = () => {
  return (
    <HomeWrapper>
      <LogoWrapper src={logo} />
      <LinksWrapper>
        <LinkWrapper
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          duration={500}>À propos</LinkWrapper>
        <LinkWrapper
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          duration={500}>Services</LinkWrapper>
        <LinkWrapper
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          duration={500}>Contact</LinkWrapper>
      </LinksWrapper>
    </HomeWrapper>
  )
}

export default HomePage