import React, { useState, useRef } from 'react'
import { ContactWrapper, EmailInput, FormWrapper, IconImg, IconText, InfoWrapper, InfosWrapper, Input, InputsWrapper, MessageInput, MessageWrapper, NamesWrapper, SectionWrapper, SubmitInput, TitleWrapper } from './ContactElements'
import { BgFrontSlash } from '../Common/CommonElements'
import bgfrontslash from '../../assets/images/backslashb.png'
import tel from '../../assets/images/phone.png'
import at from '../../assets/images/at.png'
import pin from '../../assets/images/pin.png'
import { Store } from 'react-notifications-component';
import emailjs from '@emailjs/browser';


const ContactPage = () => {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const form = useRef();

  const resetForm = (e) => {
    setName('');
    setCompany('');
    setEmail('');
    setPhone('');
    setMessage('');
  }

  const notifySuccess = (e) => {
    e.preventDefault();
    resetForm();
    Store.addNotification({
      title: "Message envoyé avec succès!",
      message: "Je serai en contact avec vous sous peu.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  var templateParams = {
    name: name,
    company: company,
    phone: phone,
    email: email, 
    message: message
  };

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    
    emailjs.init("4yPoYruMXRm_J5S23");
    emailjs.send('service_9hri4zn', 'template_iyhz7h6', templateParams)
      .then((result) => {
        notifySuccess(e);
      }, (error) => {
        // show the user an error
      });
    resetForm();
  };


  return (
    <ContactWrapper id='contact'>
      <BgFrontSlash src={bgfrontslash} />
      <TitleWrapper>Contact</TitleWrapper>
      <SectionWrapper>
        <FormWrapper ref={form} onSubmit={(e) => sendEmail(e)}>
          <NamesWrapper>
            <Input type="text" placeholder='Nom et Prénom' required value={name} onChange={(event) => setName(event.target.value)} />
            <Input type="text" placeholder='Nom de votre compagnie' required value={company} onChange={(event) => setCompany(event.target.value)} />
          </NamesWrapper>
          <NamesWrapper>
            <Input type="email" placeholder='Adresse courriel' required value={email} onChange={(event) => setEmail(event.target.value)} />
            <Input type="text" placeholder='Numéro de téléphone' required value={phone} onChange={(event) => setPhone(event.target.value)} />
          </NamesWrapper>
          <MessageInput type="textarea" wrap='hard' placeholder='Décrivez vos besoins...' value={message} onChange={(event) => setMessage(event.target.value)} />
          <SubmitInput type="submit" value="Envoyer" />
        </FormWrapper>
        <InfosWrapper>
          <InfoWrapper href="tel:5148501313">
            <IconImg src={tel} />
            <IconText>514-850-1313</IconText>
          </InfoWrapper>
          <InfoWrapper href="mailto:nataly.dion@hotmail.com">
            <IconImg src={at} />
            <IconText>Nataly.Dion@Hotmail.Com</IconText>
          </InfoWrapper>
          <InfoWrapper>
            <IconImg src={pin} />
            <IconText>Laval, Québec</IconText>
          </InfoWrapper>
        </InfosWrapper>
      </SectionWrapper>
    </ContactWrapper>
  )
}

export default ContactPage