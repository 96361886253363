import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100vw;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.color.primary};
`

export const FooterLogo = styled.div`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => props.theme.fontSize.ml};
  color: ${props => props.theme.color.bright};
`

export const FooterRights = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${props => props.theme.fontSize.xxs};
  color: ${props => props.theme.color.bright};
`