import styled from 'styled-components';

export const ServicesWrapper = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: relative;
  background-color: ${props => props.theme.color.bright};
  overflow-x: hidden;
`

export const TitleWrapper = styled.div`
  position: absolute;
  padding: 50px;
  padding-top: 50px;
  color: ${props => props.theme.color.dark};
  font-size: ${props => props.theme.fontSize.lg};
  font-family: ${props => props.theme.font.primary};
  letter-spacing: .15rem;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSize.ml};
  }
`

export const SectionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 10vw;
  bottom: 10vh;
  @media screen and (max-width: 1280px) {
    position: relative;
    margin-top: 50px;
    right: auto;
    bottom: auto;
  }
`

export const PolygonWrapper = styled.div`
  height: 100vh;
  width: 100%;
  clip-path: polygon(73% 0, 63% 0, 40% 100%, 50% 100%);
  shape-outside: polygon(73% 0, 63% 0, 40% 100%, 50% 100%);
  float: right;
  shape-margin: 3%;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`

export const TextWrapper = styled.div`
  margin-top: 100px;
  padding: 50px;
  color: ${props => props.theme.color.dark};
  font-size: ${props => props.theme.fontSize.sm};
  text-align: right;
  line-height: 5vh;
  font-weight: 300;
  @media screen and (max-width: 1280px) {
    text-align: center;
    font-size: ${props => props.theme.fontSize.xs};
  }
`

export const ItemContainer = styled.div`
  margin: 20px 0;
`