import React from 'react'
import { ContentWrapper, PolygonWrapper, SectionWrapper, ServicesWrapper, TitleWrapper, TextWrapper, ItemContainer } from './ServicesElements'
import { BgBackSlash } from '../Common/CommonElements'
import bgbackslash from '../../assets/images/bg-backslash.png'
import avantage from '../../assets/images/avantage.png'
import sage from '../../assets/images/sage.jpeg'
import { AboutWrapper, FlexWrapper } from '../About/AboutElements'

const ServicesPage = () => {
  return (
    <ServicesWrapper id='services'>
      <PolygonWrapper />
      <TitleWrapper>SERVICES</TitleWrapper>
      <BgBackSlash src={bgbackslash} />
      <TextWrapper>
        La tenue de livres, comptabilisation des écritures d'achats, de ventes, de paie et de banque. <br />
        Préparation et production des rapports TPS-TVQ, remises des Déductions à la Source. <br />
        (DAS), relevés fiscaux annuels (T4 – Relevé 1).<br />
        Déclaration des salaires CNESST et rapports gouvernementaux autres.<br />
        Contrôle des écritures de fin de mois.<br />
        Préparation et comptabilisation des écritures de fin d'exercice.<br />
        Conciliation des comptes bancaires.<br />
        La préparation et traitement des paies, relevés d'emploi, suivi des vacances.<br />
        Effectuer le paiement des fournisseurs pour le client.<br />
        Suivi des comptes clients et recouvrement des factures impayées.<br />
        Préparation des états financiers mensuels et de fin d'année.<br />
        Élaboration du budget de l'organisation.<br />
        Préparation des rapports sur les finances de l'entreprise.<br />
        Calcul des coûts de production, de projets et des travaux en cours.<br />
      </TextWrapper>
      <SectionWrapper>
        <ItemContainer>LOGICIELS COMPTABLES</ItemContainer>
        <ItemContainer>
          <img src={avantage} width='250px' />
        </ItemContainer>
        <ItemContainer>
          <img src={sage} width='250px'/>
        </ItemContainer>
      </SectionWrapper>
    </ServicesWrapper>


    // <ServicesWrapper>
    //   <PolygonWrapper />
    //   <TitleWrapper>SERVICES</TitleWrapper>
    //   <BgBackSlash src={bgbackslash} />
    //   <TextWrapper>

    //   </TextWrapper>
    /* <ContentWrapper>
      <SectionWrapper>

        <TextWrapper>La tenue de livres, comptabilisation des écritures d'achats, de ventes, de paie et de banque.</TextWrapper>
        <TextWrapper>Préparation et production des rapports TPS-TVQ, remises des Déductions à la Source.</TextWrapper>
        <TextWrapper>(DAS), relevés fiscaux annuels (T4 – Relevé 1), déclaration des salaires CNESST et rapports gouvernementaux autres.</TextWrapper>
        <TextWrapper>Contrôle des écritures de fin de mois.</TextWrapper>
        <TextWrapper>Préparation et comptabilisation des écritures de fin d'exercice.</TextWrapper>
        <TextWrapper>Conciliation des comptes bancaires.</TextWrapper>
        <TextWrapper>La préparation et traitement des paies, relevés d'emploi, suivi des vacances.</TextWrapper>
        <TextWrapper>Effectuer le paiement des fournisseurs pour le client.</TextWrapper>
        <TextWrapper>Suivi des comptes clients et recouvrement des factures impayées.</TextWrapper>
        <TextWrapper>Préparation des états financiers mensuels et de fin d'année.</TextWrapper>
        <TextWrapper>Élaboration du budget de l'organisation.</TextWrapper>
        <TextWrapper>Préparation des rapports sur les finances de l'entreprise.</TextWrapper>
        <TextWrapper>Calcul des coûts de production, de projets et des travaux en cours.</TextWrapper>
      </SectionWrapper>
      <SectionWrapper></SectionWrapper>
    </ContentWrapper> */
    /* </ServicesWrapper> */
  )
}

export default ServicesPage