import styled from 'styled-components';
import { Link as Links } from 'react-scroll'

export const HomeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.color.dark};
`

export const LogoWrapper = styled.img`
  width: 40vw;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 80vw;
  }
`

export const LinksWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 40vw;
  font-size: ${props => props.theme.fontSize.md};
  @media screen and (max-width: 700px) {
    width: 80vw;
    height: 25vh;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.sm};
  }
`

export const LinkWrapper = styled(Links)`
  color: ${props => props.theme.color.secondary};
  cursor: pointer;

  &:hover {
    color: white;
    font-size: ${props => props.theme.fontSize.ml};
  }
`