import styled from 'styled-components';

export const ContactWrapper = styled.div`
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: relative;
  background-color: ${props => props.theme.color.dark};
  overflow-x: hidden;
  z-index: 10;
`

export const TitleWrapper = styled.div`
  padding: 50px;
  padding-top: 50px;
  color: ${props => props.theme.color.bright};
  font-size: ${props => props.theme.fontSize.lg};
  font-family: ${props => props.theme.font.primary};
  letter-spacing: .15rem;
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSize.ml};
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 1280px) {
    margin-bottom: 70px;
  }
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 20px;
`

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 20px;
    @media screen and (max-width: 1280px) {
      margin-top: 50px;
      width: 100%;
      align-items: center;
      font-size: ${props => props.theme.fontSize.xs};
  }

`

export const InfoWrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`

export const IconImg = styled.img`
  width: 50px;
  margin: 20px;
  @media screen and (max-width: 700px) {
    width: 30px;
  }
`

export const IconText = styled.div`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.theme.color.bright};
`


export const NamesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const InputsWrapper = styled.input`
  border-bottom: solid 1px ${props => props.theme.color.secondary};
`

export const Input = styled.input.attrs(props => ({
  type: "text",
}))`
  color: ${props => props.theme.color.bright};
  background: none;
  font-size: ${props => props.theme.fontSize.sm};
  min-width: 250px;
  border: none;
  border-bottom: solid 1px ${props => props.theme.color.secondary};
  font-family: ${props => props.theme.font.secondary};
  line-height: 30px;
  margin: 10px;
  padding: 0 5px;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    font-size: ${props => props.theme.fontSize.xs};
  }
`;

export const MessageInput = styled.textarea.attrs(props => ({
  type: "text",
}))`
  color: ${props => props.theme.color.bright};
  background: none;
  font-size: ${props => props.theme.fontSize.sm};
  min-width: 520px;
  min-height: 250px;
  border: solid 1px ${props => props.theme.color.secondary};
  font-family: ${props => props.theme.font.secondary};
  line-height: 25px;
  margin: 30px 10px;
  padding: 0 5px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 10px;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    min-width: 90vw;
    font-size: ${props => props.theme.fontSize.xs};
  }
`;

export const MessageWrapper = styled.div`
  border: solid 1px ${props => props.theme.color.secondary};
`

export const SubmitInput = styled.input.attrs(props => ({
  type: "submit",
}))`
  color: ${props => props.theme.color.bright};
  background: none;
  font-size: ${props => props.theme.fontSize.md};
  border: none;
  font-family: ${props => props.theme.font.secondary};
  line-height: 30px;
  margin: 20px 10px;
  padding: 0 5px;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    width: 90vw;
    font-size: ${props => props.theme.fontSize.sm};
  }
`;