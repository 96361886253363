import styled from 'styled-components';

export const BgFrontSlash = styled.img`
  height: 100vh;
  width: auto;
  position: absolute;
  top: 0;
  left: 40vw;
  z-index: 1;
`
export const BgBackSlash = styled.img`
  height: 100vh;
  width: auto;
  position: absolute;
  top: 0;
  left: 40vw;
  z-index: 0;
`

