import React from 'react'
import { FooterLogo, FooterRights, FooterWrapper } from './FooterElements'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterLogo>Comptabilité ND</FooterLogo>
      <FooterRights>© Tous droits réservés.</FooterRights>
    </FooterWrapper>
  )
}

export default Footer