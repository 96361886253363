import React from 'react'
import { AboutWrapper, FlexWrapper, OwnerImg, OwnerName, OwnerProfession, OwnerWrapper, PolygonWrapper, TextWrapper, TitleWrapper } from './AboutElements'
import { BgFrontSlash } from '../Common/CommonElements'
import bgfrontslash from '../../assets/images/bg-frontslash.png'
import portrait from '../../assets/images/portrait.png'

const AboutPage = () => {
  return (
    <FlexWrapper id='about'>
      <AboutWrapper>
        <PolygonWrapper />
        <TitleWrapper>À PROPOS</TitleWrapper>
        <BgFrontSlash src={bgfrontslash} />
        <TextWrapper>Possédant un DEC en comptabilité, au cours de mes 30 années d'expérience dans le domaine, j'ai
          eu la chance de me perfectionner au niveau de la comptabilité complète de A à Z. <br /> <br />
          J'ai fait mes débuts en tant que contrôleur-comptable pour un entrepreneur général en
          construction où j'y ai occupé mon poste pendant plus de 25 ans. Pour ensuite me diriger vers un
          Centre en Santé Mentale (OBNL) où j'assurais toutes les tâches comptables et de subventions
          gouvernementales. Finalement, je suis devenue spécialiste de la paie pour NovaBus, responsable
          de 800 paies hebdomadaires d'employés syndiqués avant d'ouvrir mon propre bureau de services
          comptables.</TextWrapper>
        <OwnerWrapper>
          <OwnerImg src={portrait} width="250px" />
          <OwnerName> NATALY DION</OwnerName>
          <OwnerProfession>Technicienne comptable senior</OwnerProfession>
        </OwnerWrapper>
      </AboutWrapper>
    </FlexWrapper>
  )
}

export default AboutPage